/**
 * @license
 * Copyright 2016 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */

import {MDCFoundation} from '@material/base/foundation';
import {MDCGridListAdapter} from './adapter';
import {strings} from './constants';

export class MDCGridListFoundation extends MDCFoundation<MDCGridListAdapter> {
  static get strings() {
    return strings;
  }

  static get defaultAdapter(): MDCGridListAdapter {
    return {
      deregisterResizeHandler: () => undefined,
      getNumberOfTiles: () => 0,
      getOffsetWidth: () => 0,
      getOffsetWidthForTileAtIndex: () => 0,
      registerResizeHandler: () => undefined,
      setStyleForTilesElement: () => undefined,
    };
  }

  private readonly resizeHandler_: EventListener;
  private resizeFrame_ = 0;

  /* istanbul ignore next: optional argument is not a branch statement */
  constructor(adapter?: Partial<MDCGridListAdapter>) {
    super({...MDCGridListFoundation.defaultAdapter, ...adapter});

    this.resizeHandler_ = this.alignCenter.bind(this);
  }

  init() {
    this.alignCenter();
    this.adapter_.registerResizeHandler(this.resizeHandler_);
  }

  destroy() {
    this.adapter_.deregisterResizeHandler(this.resizeHandler_);
  }

  alignCenter() {
    cancelAnimationFrame(this.resizeFrame_);
    this.resizeFrame_ = requestAnimationFrame(() => {
      this.alignCenter_();
      this.resizeFrame_ = 0;
    });
  }

  private alignCenter_() {
    if (this.adapter_.getNumberOfTiles() === 0) {
      return;
    }
    const gridWidth = this.adapter_.getOffsetWidth();
    const itemWidth = this.adapter_.getOffsetWidthForTileAtIndex(0);
    const tilesWidth = itemWidth * Math.floor(gridWidth / itemWidth);
    this.adapter_.setStyleForTilesElement('width', `${tilesWidth}px`);
  }
}

// tslint:disable-next-line:no-default-export Needed for backward compatibility with MDC Web v0.44.0 and earlier.
export default MDCGridListFoundation;
