/**
 * @license
 * Copyright 2016 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */

import {MDCComponent} from '@material/base/component';
import {MDCGridListAdapter} from './adapter';
import {MDCGridListFoundation} from './foundation';

export class MDCGridList extends MDCComponent<MDCGridListFoundation> {
  static attachTo(root: Element) {
    return new MDCGridList(root);
  }

  getDefaultFoundation() {
    // DO NOT INLINE this variable. For backward compatibility, foundations take a Partial<MDCFooAdapter>.
    // To ensure we don't accidentally omit any methods, we need a separate, strongly typed adapter variable.
    const adapter: MDCGridListAdapter = {
      deregisterResizeHandler: (handler) => window.removeEventListener('resize', handler),
      getNumberOfTiles: () => {
        return this.root_.querySelectorAll(MDCGridListFoundation.strings.TILE_SELECTOR).length;
      },
      getOffsetWidth: () => (this.root_ as HTMLElement).offsetWidth,
      getOffsetWidthForTileAtIndex: (index) => {
        const tileEl = this.root_.querySelectorAll<HTMLElement>(MDCGridListFoundation.strings.TILE_SELECTOR)[index];
        return tileEl.offsetWidth;
      },
      registerResizeHandler: (handler) => window.addEventListener('resize', handler),
      setStyleForTilesElement: (property, value) => {
        const tilesEl = this.root_.querySelector<HTMLElement>(MDCGridListFoundation.strings.TILES_SELECTOR);
        tilesEl!.style[property] = value;
      },
    };
    return new MDCGridListFoundation(adapter);
  }
}
